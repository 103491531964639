import React, { useState, useEffect } from "react";
import { useClient } from "../../../context/ClientContext";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Text,
  Box,
  Tooltip,
  Flex,
  Skeleton,
  MenuDivider,
  Avatar,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import { IoMdFemale, IoMdMale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { useApplication } from "../../../context/ApplicationContext";
import { useJD } from "../../../context/JDContext";
import { useCandidate } from "../../../context/CandidateContext";
import CandidateDetails from "../Candidate/CandidateDetails";
import JdDetails from "../JobDescription/JdDetails";
import ApplicationDetails from "../Application/ApplicationDetails";

const ClientApplications = ({ ClientId }) => {
  const { fetchClientApplications, clientApplications } = useClient();
  const { backendUrl } = useEnv();
  const [sortedData, setSortedData] = useState([]);
  const {
    candidateData,
    setCandidateData,
    fetchSpecificCandidate,
    loadingCandidate,
  } = useCandidate();
  const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } =
    useJD();

  // Handle view modals
  const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] =
    useState(false);
  const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] =
    useState(false);

  // Sync sortedData with clientApplications
  useEffect(() => {
    if (clientApplications && clientApplications.length > 0) {
      setSortedData(clientApplications);
    }
  }, [clientApplications]);

  // Fetch applications for the client when ClientId changes
  useEffect(() => {
    if (ClientId) {
      fetchClientApplications(ClientId);
    }
  }, [ClientId, fetchClientApplications]);

  // Render content
  if (!clientApplications || clientApplications.length === 0) {
    return <div>No Applications Found</div>;
  }
  //   console.log(clientJds)    const { backendUrl } = useEnv();
  console.log(sortedData, clientApplications);

  // Handle Candidate Details modal Preview
  const handleCandidatePreview = (candidate) => {
    // fetch the candidate
    fetchSpecificCandidate(candidate?._id);
    setIsCandidateDetailsModalOpen(true);
  };

  const handleCandidateViewCloseModal = () => {
    setIsCandidateDetailsModalOpen(false);
    setCandidateData(null);
  };

  // Handle JD Details modal Preview
  const handleJDPreview = (jd) => {
    // fetch the jd
    fetchJDById(jd._id);
    setIsJDDetailsModalOpen(true);
  };

  const handleJDViewCloseModal = () => {
    setIsJDDetailsModalOpen(false);
    setJobDescriptionData(null);
  };

  // Handle Application Details modal Preview
  const handleApplicationPreview = () => {
    setIsApplicationDetailsModalOpen(true);
  };
  const handleApplicationViewCloseModal = () => {
    setIsApplicationDetailsModalOpen(false);
    setSelectedApplication(null);
  };

  return (
    <div
      style={{
        maxHeight: "79vh",
        overflow: "auto",
        position: "relative",
      }}
    >
      <TableContainer
        style={{ position: "relative" }}
        overflowY={sortedData.length < 5 ? "auto" : "clip"}
        overflowX={sortedData.length < 5 ? "clip" : "clip"}
      >
        <Table size="sm">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#05080D",
              zIndex: 1,
            }}
          >
            <Tr p="8">
              <Th p={3} fontSize={"sm"} style={{ color: "white" }}>
                Sl
              </Th>
              <Th fontSize={"small"} style={{ color: "white" }}>
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>Application Id</Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click to view the Application Details
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    App. Id
                  </Tooltip>{" "}
                </Flex>
              </Th>
              <Th
                fontSize={"small"}
                style={{
                  color: "white",
                }}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text fontSize={"sm"} textAlign={"center"}>
                          Job Id, Job Title
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on job Id to view the JD details
                        </Text>
                      </Box>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Job Description
                  </Tooltip>
                </Flex>
              </Th>
              <Th
                fontSize={"small"}
                style={{
                  color: "white",
                }}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Flex align={"center"}>
                          <Avatar
                            src=""
                            size="sm"
                            name="Display Picture"
                            mr={2}
                            background={"green"}
                            color={"white"}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              fontSize={"sm"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              Recruiter Name
                            </Text>
                            <Text
                              fontSize={"sm"}
                              color={"#2b2a2a"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              Employee Id
                            </Text>
                          </Box>
                        </Flex>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Recruiter
                  </Tooltip>
                </Flex>
              </Th>
              <Th
                fontSize={"small"}
                style={{
                  color: "white",
                }}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Flex align={"center"}>
                          <Avatar
                            src=""
                            size="sm"
                            name="Display Picture"
                            mr={2}
                            background={"green"}
                            color={"white"}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              fontSize={"sm"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <b>Candidate Id</b>
                              {" - "}
                              Name, gender
                            </Text>
                            <Text
                              fontSize={"sm"}
                              color={"#2b2a2a"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              Candidate Email
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          direction={"column"}
                          fontSize={"xs"}
                          color={"gray"}
                          mt={2}
                        >
                          <Text color={"black"}>Gender Details: </Text>
                          <Flex
                            align={"center"}
                            gap={1}
                            background={"black"}
                            p={1}
                            px={2}
                            color={"white"}
                          >
                            <IoMdMale color="#2D9AFE" />
                            <Text>Male, </Text>
                            <IoMdFemale color="#F86CFF" />
                            <Text>Female, </Text>
                            <IoMaleFemale color="#ffd600" />
                            <Text>Other</Text>
                          </Flex>
                        </Flex>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on the DP to view the candidate details <br />
                          Click on mail id to mail the candidate?.
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Candidate
                  </Tooltip>
                </Flex>
              </Th>

              <Th fontSize={"small"} style={{ color: "white" }}>
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>
                          Application Current Status
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={"#575757"}
                          textAlign={"center"}
                        >
                          Status change date and time
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"#575757"}
                          maxW={"200px"}
                          m={2}
                          textAlign={"center"}
                        >
                          If Status is Rejected then hover on it to see
                          Rejection Remarks.
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Cur. St. & Time
                  </Tooltip>{" "}
                </Flex>
              </Th>

              <Th fontSize={"small"} style={{ color: "white" }}>
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label="Application Created On"
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Created on
                  </Tooltip>{" "}
                </Flex>
              </Th>
              <Th fontSize={"small"} style={{ color: "white" }}>
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label="Application Status"
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Status
                  </Tooltip>{" "}
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData &&
              sortedData.map((application, index) => (
                <Tr key={index}>
                  <Td p={4}>{index + 1}</Td>
                  <Td style={{ cursor: "pointer" }}>
                    <Tooltip
                      hasArrow
                      label={
                        <Text fontSize={"xs"} color={"gray"}>
                          Click to view <b>{application?.applicationID}</b> in
                          Details
                        </Text>
                      }
                      bg="gray.300"
                      color="black"
                      placement="top"
                    >
                      <Box
                        onClick={() => {
                          setSelectedApplication(application);
                          handleApplicationPreview(application);
                        }}
                      >
                        {application?.applicationID}
                      </Box>
                    </Tooltip>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "230px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text fontSize={"sm"}>
                        <Tooltip
                          hasArrow
                          label={
                            <Text fontSize={"xs"} color={"gray"}>
                              Click to view{" "}
                              <b>{application?.jobDescription?.jobId}</b> in
                              Details
                            </Text>
                          }
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Flex
                            cursor={loadingJD ? "progress" : "pointer"}
                            onClick={() => {
                              if (!loadingJD)
                                handleJDPreview(application?.jobDescription);
                            }}
                          >
                            {application?.jobDescription?.jobId}
                          </Flex>
                        </Tooltip>
                      </Text>

                      <Text
                        fontSize={"sm"}
                        color={"#a3a3a3"}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "180px",
                        }}
                      >
                        {application?.jobDescription?.jobTitle}
                      </Text>
                    </Box>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <Flex>
                      {/* <Avatar
                                                    isLazy
                                                    src={`${backendUrl}${application?.addedBy?.dp}`}
                                                    size="sm"
                                                    name={application?.recruiter?.name}
                                                    mr={2}
                                                    background={"green"}
                                                    color={"white"}
                                                /> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Flex gap={1}>
                          <Text
                            fontSize={"sm"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "180px",
                            }}
                          >
                            {application?.recruiter?.name}
                          </Text>
                        </Flex>
                        <Text
                          fontSize={"sm"}
                          color={"#a3a3a3"}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "180px",
                          }}
                        >
                          {application?.recruiter?.empId}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <Flex>
                      <Tooltip
                        hasArrow
                        label={
                          <Text fontSize={"xs"} color={"gray"}>
                            Click to view Candidate{" "}
                            <b>{application?.candidate?.stuId}</b> in Details
                          </Text>
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Avatar
                          src={`${backendUrl}${application?.candidate?.dp}`}
                          size="sm"
                          name={application?.candidate?.name}
                          mr={2}
                          background={"green"}
                          color={"white"}
                          cursor={loadingCandidate ? "progress" : "pointer"}
                          onClick={() => {
                            if (!loadingCandidate)
                              handleCandidatePreview(application?.candidate);
                          }}
                        />
                      </Tooltip>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Flex gap={1}>
                          <Text
                            fontSize={"sm"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "150px",
                            }}
                          >
                            <span
                              style={{
                                color: "#a3a3a3",
                                fontWeight: "bold",
                              }}
                            >
                              {application?.candidate?.stuId}
                            </span>
                            {" - "}
                            {application?.candidate?.name}
                          </Text>
                          {getGenderIcon(application?.candidate?.gender)}
                        </Flex>
                        <Text
                          fontSize={"sm"}
                          color={"#a3a3a3"}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          <Tooltip
                            hasArrow
                            label={
                              <Text fontSize={"xs"} color={"gray"}>
                                Click to mail the Candidate
                              </Text>
                            }
                            bg="gray.300"
                            color="black"
                            placement="bottom"
                          >
                            <a
                              target="_blank"
                              href={`mailto:${application?.candidate?.email}`}
                            >
                              {application?.candidate?.email}
                            </a>
                          </Tooltip>
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    {application?.applicationStatus == "Rejected" ? (
                      <Tooltip
                        hasArrow
                        label={
                          application?.rejectionRemarks && (
                            <>
                              Remark:
                              <Box whiteSpace="pre-line">
                                {application?.rejectionRemarks}
                              </Box>
                            </>
                          )
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Flex direction={"column"}>
                          {application?.currentState}
                          <Text fontSize={"xs"} color={"#a3a3a3"}>
                            {formatDateTime(application?.updatedAt)}
                          </Text>
                        </Flex>
                      </Tooltip>
                    ) : (
                      <Flex direction={"column"}>
                        {application?.currentState}
                        <Text fontSize={"xs"} color={"#a3a3a3"}>
                          {formatDateTime(application?.updatedAt)}
                        </Text>
                      </Flex>
                    )}
                  </Td>
                  <Td>{formatDateTime(application?.createdAt)}</Td>
                  <Td>
                    {application?.applicationStatus == "Not Started" && (
                      <Badge variant="subtle" colorScheme="yellow">
                        Not Started
                      </Badge>
                    )}
                    {application?.applicationStatus == "On Process" && (
                      <Badge variant="subtle" colorScheme="blue">
                        On Process
                      </Badge>
                    )}
                    {application?.applicationStatus == "Selected" && (
                      <Badge variant="subtle" colorScheme="green">
                        Selected
                      </Badge>
                    )}
                    {application?.applicationStatus == "Rejected" && (
                      <Badge variant="subtle" colorScheme="red">
                        Rejected
                      </Badge>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      {candidateData && isCandidateDetailsModalOpen && (
        <CandidateDetails
          candidate={candidateData}
          isOpen={isCandidateDetailsModalOpen}
          onClose={handleCandidateViewCloseModal}
        />
      )}
      {jobDescriptionData && isJDDetailsModalOpen && (
        <JdDetails
          jd={jobDescriptionData}
          isOpen={isJDDetailsModalOpen}
          onClose={handleJDViewCloseModal}
        />
      )}
      {selectedApplication && isApplicationDetailsModalOpen && (
        <ApplicationDetails
          application={selectedApplication}
          isOpen={isApplicationDetailsModalOpen}
          onClose={handleApplicationViewCloseModal}
        />
      )}
    </div>
  );
};

export default ClientApplications;
